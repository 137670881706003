<template>
    <v-app>
        <v-container grid-list-md text-xs-center fluid>
            <h1>VPN</h1>
            <v-divider class="mb-4" />
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Placa</th>
                            <th class="text-left">Status</th>
                            <th class="text-left">VPN</th>
                            <th class="text-left">Último acesso</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="v in vpn" :key="v.board">
                            <td>{{ v.board }}</td>
                            <td>
                                <v-chip v-if="v.pending" class="ma-2" color="warning" text-color="white"> Pendente </v-chip>
                                <v-chip v-else-if="v.instance" class="ma-2" color="green" text-color="white"> Ok </v-chip>
                                <v-chip v-else class="ma-2" color="error" text-color="white"> Não localizado </v-chip>
                            </td>
                            <td>
                                <v-btn v-if="v.IP" small color="blue-grey" class="white--text" :href="`http://${v.IP}`" target="_blank">
                                    <v-icon left>vpn_lock</v-icon>
                                    VPN: {{ v.IP }}
                                </v-btn>
                            </td>
                            <td>{{ v.lastAccess }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            vpn: [],
        };
    },

    created() {
        this.update();
        this.timer = setInterval(this.update, 5000);
    },

    methods: {
        update() {
            this.$http
                .get("api/v2/vpn", { continuous: true })
                .then((result) => {
                    this.vpn = result;
                })
                .catch(() => {
                    this.vpn = [];
                });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
